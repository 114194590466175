<template>
<!--  企业简介-->
  <div class="case">
    <img src="../assets/img/qyjj.png"  style="width: 100%"/>
    <div style="margin:auto;width: 50%">
      <h1 class="title">企业简介</h1>
      <div style="font-size: 10px;font-family: PingFang SC, PingFang SC-Regular;font-weight: Regular;color: #333333;line-height: 14px;text-align:center">COMPANY PROFILE</div>
      <div class="stitle">22年行业深耕</div>
      <span class="content">&nbsp;&nbsp;长吉物流创始于1999年，隶属长吉集团，总部位于辽宁沈阳，经过22年的稳步发展，现已成为东北乃至全国首屈一指的大型零担物流运输公司。截止目前，长吉拥有营业网点700余家，员工5000余人，运输车辆4000余辆，仓储面积超过35万平方米;公司现有沈阳、长春、哈尔滨、济南、成都五大区域，业务涵盖辽宁、吉林、黑龙江、内蒙古、山东、四川、河北、天津八个省份。      22年的稳步发展与积累，为长吉布局全国网络奠定了坚实基础;长吉秉承“让客户生意更好、让人才实现梦想”的企业使命，始终坚持“合作第一、组织为大、用服务说话”的企业价值观，倡导企业员工用实际行动践行“一切为了客户我愿意!”的企业口号，全力以赴向成为“物流新生态领航者”的企业愿景奋力前行。</span>
      <div class="stitle">自主研发物流管理系统从数据化到数智物联是追求</div>
      <span class="content">&nbsp;&nbsp;作为东北乃至全国首屈一指的大型零担物流企业,近年来长吉物流在打造物流管理平台,积极利用数字化提升运营水平,利用智能化服务带来更良好用户体验以及通过信息化建设打造先进管理模式方面成效显著,也将为未来实现物联网+物流的高水平运作和管理模式打下了坚实基础。在未来为广大客户创造多元、灵活、高效服务。</span>
      <div  class="stitle">特色物流道路推之而上长吉“有爱”是基石</div>
      <span class="content">&nbsp;&nbsp;长吉物流走着具有自身发展特色的物流产业管理道路，一方面，打造高素质运作管理团队，实行人性化、网络化企业管理，同进步、共创富，让人才实现梦想，有爱长吉正在传递;另一方面，长吉物流在与上百家企业持有长期货运合作关系的同时，整合多方资源，发挥其零担物流行业主流优势，挖掘新型运营模式，赢得更多客户信任与青睐，用实际行动让客户生意更好。      春秋数载，长吉物流将在未来依托现代化物流系统和服务，帮助客户实现未来成功的愿景，为中国物流提供更多优质满意贴心的服务，为此，长吉一直在通往远大目标的路上!</span>
      <!--<div style="width: 104px;height: 40px;background: #d43f2e;border-radius: 8px;color:white;line-height: 40px;margin:70px auto;text-align:center">发展历程</div>-->
      <!--<div style="text-align: center">-->
        <!--<img src="../assets/img/fzlc.png"  />-->
      <!--</div>-->
    </div>
    <div class="footer">
      <p style="margin:25px auto;">全国客服电话：4006175757</p>
<!--      <p style="padding-top:20px;margin-left: 400px">微信订阅号：</p>-->
<!--      <p style="padding-top:20px;margin-left: 100px">微信服务号：</p>-->
    </div>
  </div>
</template>
<script>
export default {
  name:"intro",
  data() {
    return {
      loading: true,
      caseList: []
    };
  },
  mounted() {

  }
};
</script>

<style lang="scss" scoped>
.footer{
  bottom: 0;
  width: 100%;
  background-color: #2F7BFF;
  height: 72px;
  font-size: 18px;
  font-family: PingFang SC, PingFang SC-Medium;
  font-weight: bold!important;
  text-align: left;
  color: #ffffff;
  display: flex;
}
.content{
  font-size: 14px;
  font-family: PingFang SC, PingFang SC-Regular;
  //font-weight: bold!important;
  text-align: justify!important;
  color: #333333;
  line-height: 24px;margin: 14px ;
}
.stitle{
  font-size: 14px;
  font-family: PingFang SC, PingFang SC-Medium;
  font-weight: bold;
  text-align: center;
  color: #333333;
  line-height: 24px;
  margin: 30px auto;
}
.title {
  font-size: 18px;
  font-family: PingFang SC, PingFang SC-Medium;
  font-weight: bold;
  text-align: center;
  color: #333333;
  line-height: 18px;
  letter-spacing: 0.05px;
  position: relative;
}
.title:before {
  content: "";
  position: absolute;
  width: 85px;
  height: 2px;
  top: 50%;
  background-color: #979797;
  left: 30%;
}
.title:after {
  content: "";
  position: absolute;
  width: 85px;
  height: 2px;
  top: 50%;
  background-color:#979797;
  right: 30%;
}
.case {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  color:black;
}
</style>
